import type {
  BaseInstance,
  ModelName,
  RelationalFieldValue,
} from '@pigello/pigello-matrix';
import { ObjectKeys } from '../utils';

export const isThin = (obj?: Record<string, any> | null): boolean => {
  if (!obj) return true;
  if (Object.keys(obj).length !== 1) return false;
  return true;
};

/**
 * @description
 * Used to unwrapp and set the correct types on nested field values that is
 * returned when specifying the nested option on api hooks.
 *
 * @param relation - The relation you want to unwrapp.
 * @returns relation with the infered type if defined, otherwise return null
 * @example
 * ```tsx
 * const data = { data } = useGetApartment({nested: ['someNestedField']})
 * const someNestedField = unwrappRelationFieldValue(data.someNestedField);
 *       ^ SomeNestedFieldType | null                     ^ RelationFieldValue<SomeNestedFieldType>
 * ```
 */
export function unwrapRelationFieldValue<TInstance extends BaseInstance>(
  relation: RelationalFieldValue<TInstance>
) {
  if (
    relation !== undefined &&
    relation !== null &&
    // A relation will always include the id properties, "> 1" verifies that
    // the other properties are included and thus its a nested relation
    ObjectKeys(relation).length > 1
  )
    return relation as TInstance;
  return null;
}

/**
 * @description
 * A map object of all modelNames and their corresponding filter key. To be used when doing filtering within api requests.
 *
 * @todo check this list before usage and add field if missing
 */
export const modelNameFilterMap: Partial<Record<ModelName, string>> = {
  industrialpremises: 'industrial_premises',
  brfapartment: 'brf_apartment',
  outdoorarea: 'outdoor_area',
  outdoorsection: 'outdoor_section',
  commonarea: 'common_area',
  parkinglot: 'parking_lot',
  parkingspot: 'parking_spot',
};

/**
 * @description
 * Used to get the correct filter key when doing generic api requests where
 * modelName is dynamic.
 *
 * @param modelName - The modelName to get the filter key for.
 * @returns the correct filter key for the given modelName, if modelName does not
 * have a value in the list, the modelName will be return. For example for
 * cases like 'apartment'
 *
 * @example
 * ```industrailpremises --> industrial_premises```
 *
 * @todo !check this list before usage and add field if missing!
 */
export function getModelNameFilterKey(modelName: ModelName) {
  const filter = modelNameFilterMap[modelName];

  return filter ?? modelName;
}
